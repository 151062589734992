<template>
    <div class="app-container app-theme-white body-tabs-shadow">
        <div class="app-container">
            <div class="">
                <div class="d-flex">
                    <div class="text-center mt-4 float-left">
                        <img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/header_logo.png" alt="">
                    </div>
                </div>
                <div class="no-gutters row text-center mb-5">
                    <div class="col-md-12 text-center" v-if="!checking">
                        
                        <!-- <div class="flex flex-col text-center mt-24 text-gray-500 mb-10 md:mt-20 text-4xl">
                            <span class="mx-auto mt-5">
                                <svg class="animate-spin h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </span>
                        </div> -->
                    </div>

                    <div class="col-md-12 text-center" v-if="!checking">
                        <div class="flex flex-col text-center mt-24 text-gray-500 mb-10 md:mt-20 text-2xl">
                            {{ $t('hi') }} {{ $route.query.name }}! <br> {{ $t('confirmInvite') }}<br> {{ $t('redirectYou') }} <br><br>
                            <span class="mx-auto mt-5">
                                <svg class="animate-spin h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <!-- <div class="col-md-12 text-center" v-else>
                        <div class="flex flex-col text-center mt-24 text-gray-500 mb-10 md:mt-20 text-4xl">
                            Hi John Aubyn, your confirmation link expired. <br><br>
                            <span class="mx-auto mt-5">
                                <button type="submit" class="btn rounded bg-green-500 dark:bg-gray-700 text-xl" :disabled='resending' @click="resendConfirmation">
                                    <div class="flex items-center m-auto" v-if="resending">
                                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Resending Link
                                    </div>
                                    <span class="m-auto" v-else>Resend</span>
                                </button>
                            </span>
                        </div>
                    </div> -->
                </div>
                <div class="absolute bottom-0 w-full">
                    <div class="text-center py-10">
                        <span class="text-center">Copyright © <span>{{ current }} Automatisera</span></span><br>
                        <span>{{ $t('switch') }} 
                            <a @click="changeLocale('en')" href="javascript:void(0);" class="font-bold no-deco no-de-hover link-color" v-if="GET_LOCALE_LANG === 'se'">{{ $t('english') }}</a>
                            <a @click="changeLocale('se')" href="javascript:void(0);" class="font-bold no-deco no-de-hover link-color" v-else>Swedish</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import i18n from '@/plugins/i18n'

    export default {
        name : 'login',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert')
        },
        data () {
            return {
                current : new Date().getFullYear(),
                baseURL : window.location.origin,
                password : '',
                confirm : '',
                checking : true,
                processing : false,
                resending : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                GET_USER_UTIL : 'accountant/GET_USER_UTIL',
                GET_LOCALE_LANG : 'utility/GET_LOCALE_LANG'
            })
        },
        mounted () {
            const { name } = this.$route.query
            if (name) {
                this.checking = false
                setTimeout(() => {
                    this.$router.push({ name : 'login' })
                    // window.location.href = '/'
                }, 5000)
            }
        },
        beforeMount () {
            // If user token is set, proceed to dashboard
            // if (this.USER_TOKEN) {
            //     if (this.GET_USER_UTIL === true) {
            //         window.location.href = this.baseURL + '/accountant' 
            //     } else {
            //         window.location.href = this.baseURL + '/automatisera'    
            //     }
            // }
        },
        methods : {
            changeLocale (locale) {
                i18n.locale = locale
                this.$store.commit('utility/CHANGE_LOCALE', locale)
            },
            // processInvite () {
            //     if (this.password === '' || this.confirm === '') {
            //         this.$services.helpers.notification('All fields are required', 'error', this)
            //     } else if (this.password.length < 6) {
            //         this.$services.helpers.notification('Password should be greater than 6', 'error', this)
            //     } else if (this.password !== this.confirm) {
            //         this.$services.helpers.notification('Passwords do no match', 'error', this)
            //     } else {
            //         this.processing = true
            //         // 
            //     }
            // },
        }
    }
</script>

<style lang="scss" scoped>
@import "~vue-tour/dist/vue-tour.css";

#app{
    overflow-y: hidden !important;
}
.login-head-text{
    font-size: 1.2rem !important;
}
.login-head-text-xl{
    font-size: 1.5rem !important;
}
.override-form-field{
    border: 0;
    border-bottom: 1px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: unset !important;
    border-radius: unset !important;
}

.override-pt{
    padding-top: 1.2rem !important;
}
.override-pb{
    padding-bottom: unset !important;
}
.custom-rem{
    padding: 5rem;
}

</style>